/**
 * Important: use a global symbol to avoid any sort of clash. This creates a unique reference.
 */
const serverSym = Symbol.for('__TROON_SERVER_CONFIG__');
const clientSym = Symbol.for('__TROON_CLIENT_CONFIG__');
if (typeof window === 'undefined') {
	const serverConfig: Record<string, string> = {};
	const clientConfig: Record<string, string> = {};

	for (const [key, val] of Object.entries(process.env)) {
		const normalizedValue = val === 'true' || val === 'false' ? JSON.parse(val) : val!;

		if (key.startsWith('TROON_SERVER_')) {
			serverConfig[key.replace(/^TROON_SERVER_/, '')] = normalizedValue;
		} else if (key.startsWith('TROON_CLIENT_')) {
			clientConfig[key.replace(/^TROON_CLIENT_/, '')] = normalizedValue;
		} else if (key.startsWith('TROON_')) {
			serverConfig[key.replace(/^TROON_/, '')] = normalizedValue;
			clientConfig[key.replace(/^TROON_/, '')] = normalizedValue;
		}
	}
	// @ts-expect-error TS still doesn't let you declare a symbol as an index
	global[serverSym] = serverConfig;
	// @ts-expect-error
	global[clientSym] = clientConfig;
}

export type Config = {
	API_URL: string;
	BETTERSTACK_ENABLED: boolean;
	BETTERSTACK_TOKEN: string;
	HOST: string;
	LOG_LEVEL?: string;
	MAP_HOST: string;
	MAPBOX_ACCESS_TOKEN: string;
	POSTHOG_ENABLED: boolean;
	POSTHOG_WRITE_KEY: string;
	POSTHOG_API_KEY?: string;
	POSTHOG_ENABLE_SESSION_RECORDINGS?: boolean;
	SENTRY_DSN: string;
	SENTRY_ENABLED: boolean;
	SENTRY_REPORT_URI?: string;
	STRIPE_PUBLISHABLE_KEY: string;
	ENVIRONMENT?: 'development' | 'staging' | 'production';
	WONDERPUSH_KEY?: string;
	NONCE: boolean;
};

declare global {
	interface Window {
		__TROON_CONFIG__: Config;
	}
}

export function getConfigValue<K extends keyof Config, T = Config[K]>(key: K, fallback?: T): T {
	return typeof window !== 'undefined' && window.__TROON_CONFIG__
		? window.__TROON_CONFIG__[key]
		: // @ts-expect-error
			global[serverSym]
			? // @ts-expect-error
				global[serverSym][key]
			: fallback;
}

export function getConfig(type: 'client' | 'server'): Config {
	// @ts-expect-error
	return type === 'client' ? global[clientSym] : global[serverSym];
}
